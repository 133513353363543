<template>
  <b-card title="Manage Promo Code">
    <b-col cols="12">
      <b-row class="mb-2">
        <b-col cols="4">
          <b-button v-if="permission.add" @click="toCreateCampaign" variant="success">Create Batch Code</b-button>
        </b-col>
        <b-col cols="4"/>
        <b-col cols="4">
          <b-form-input
            v-model="search"
            type="search"
            placeholder="Type to Search"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-table 
            :items="campaignNumber"
            :fields="campaignFields" 
            :filter="search"
            :current-page="currentPage"
            :per-page="perPage"
            responsive
            show-empty
          >
            <template #cell(actions)="row">
              <b-dropdown  id="dropdown-dropleft" variant="primary" size="sm" text="Menu" no-caret>
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item style="text-align:center">
                  <b-button @click="toDetail(row.item.campaign._id)" class="mr-1" variant="info">
                    <feather-icon icon="EyeIcon"/>
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            :total-rows="dataSearch"
            :per-page="perPage"
            v-model="currentPage"
            style="float: right"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {userAccess} from '@/utils/utils'

export default {
  data() {
    return {
      campaignFields: [
        { key: "campaignNumber", label: "Campaign No",filterable: true, sortable: true },
        { key: "batchNumber", label: "No of Batch" },
        { key: "campaign.name", label: "Campaign",filterable: true, sortable: true },
        { key: "actions", label: "Actions" },
      ],
      search: '',
      currentPage: 1,
      perPage: 5
    };
  },
  mounted() {
    this.getCampaignNumber();
  },
  methods: {
    ...mapActions({
      getBatchsPromocode: "pcode/getBatchsPromocode",
      getCampaignNumber:"pcode/getCampaignNum"
    }),
    toCreateCampaign() {
      this.$router.push(`/promo/promocode/generate`);
    },
    toEditBatch(id) {
      this.$router.push(`/promo-code/edit/${id}`);
    },
    toDetail(id) {
      this.$router.push(`/promo/promo-code-batch/${id}`);
    },
  },
  computed: {
    ...mapState({
      batchPromoCode: (state) => {
        return state.pcode.batchPromoCode;
      },
      campaignNumber: (state) => {
        return state.pcode.campaignNumber;
      },
    }),
    dataSearch(){
      let paginationLength;
     
     paginationLength = this.campaignNumber.filter(el => {
          return el.campaign.name.includes(this.search);
      })
      console.log(paginationLength)
      if(this.search != ''){
          return paginationLength.length
      }else{
          return this.campaignNumber.length
      }
    },
    permission() {
      return userAccess("Promo Code", "warranty_master_menu");
    },
  },
  created() {
    document.title = 'Promo Code | RSP'
  },
};
</script>
